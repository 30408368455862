import React from "react";

import "./FeatTalentChoices.scss";
import {CharacterFeatures} from "./";

const ForceChoices = (props) => {
    return (
        <div className="feat-talent-choices-container">
            <div className="header box">
                <div className="title">
                    <span className="name">Force Powers</span>
                </div>
                <CharacterFeatures featureType="forcePower" {...props} />
            </div>
        </div>
    );
}

export default ForceChoices;