import React, {useState} from 'react';

import {Button, Popover, PopoverBody, PopoverHeader} from "reactstrap";

import './BoxWithTotal.scss';

const BoxWithTotal = (props) => {
    const [popoverOpen, setPopoverOpen] = useState(false);
    const { total, label } = props;
    const id = label.replace(" ", "") + "-box";

    const toggle = () => setPopoverOpen(p => !p);

    let reset = null;
    if (label === "Force") {
        reset = (
            <span
                className="cta"
                onClick={props.reset}
            >
                Reset Force Points
            </span>
        );
    }

    return (
        <div className="fp-container">
            <div
                className="fp-box"
                onMouseEnter={toggle}
            >
                <span className="fp-total" id={id}>{total}</span>
            </div>
            <span className="fp-label">{label}</span>
            <Popover
                placement="left"
                isOpen={ popoverOpen}
                target={id}
                toggle={toggle}
                trigger="hover"
            >
                <PopoverHeader>{label}</PopoverHeader>
                <PopoverBody>
                    <div className="popover-fp-container">
                        <div className="controls">
                            <Button disabled={total <= 0} onClick={props.decrement}>-</Button>
                            <span className="popover-total">{total}</span>
                            <Button onClick={props.increment}>+</Button>
                        </div>
                        {reset}
                    </div>

                </PopoverBody>
            </Popover>

        </div>
    );
}

export default BoxWithTotal;