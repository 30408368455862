import {
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    createUserWithEmailAndPassword
} from "firebase/auth";
import { auth } from "../firebase";
import {redirect} from "react-router-dom";
import * as Utils from "../utils/utils";
import * as Helpers from "../utils/apiHelpers";

export async function logout() {
    await signOut(auth);
}

export async function login({ username: email, password }) {
    return signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            const user = userCredential.user;
            return user.accessToken;
        })
        .catch(({ message: errorMessage }) => {
            console.error('Firebase sign-in error', errorMessage);
            return async () => { return [null, errorMessage]; }
        });
}

export async function loginWithPopupProvider(providerClass) {
    return signInWithPopup(auth, new providerClass())
        .then(result => {
            // const credential = providerClass.credentialFromResult(result);
            const user = result.user;
            return user.accessToken;
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData?.email;
            // The AuthCredential type that was used.
            const credential = providerClass.credentialFromError(error);

            console.error(`Firebase sign-in error code=${errorCode} message=${errorMessage} email=${email}`, credential);
            return async () => { return [null, errorMessage]; }
        });
}

export async function register({ email, password}) {
    return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed up
            const user = userCredential.user;
            return user.getIdToken();
        })
        .catch(({ code: errorCode, message: errorMessage }) => {
            console.error('Firebase create error', errorCode, errorMessage);
        });
}

export async function validateToken(token) {
    return await fetch(process.env.REACT_APP_BACKEND_HOST + `/api/v1/status`, {
        method: 'POST',
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    }).then(response => {
        return response.status >= 200 && response.status < 300;
    });
}

export function loadCharacter(id, token) {
    const path = `/api/v2/character/swse/character/${id}`;
    return Helpers.getAsJson(path, token)
        .catch(e => {
            console.log(e);
            redirect("/error")
        });
}

export function createCharacter(character, token) {
    const path = `/api/v2/character/swse/character`;
    const body = { ...character };
    return Helpers.postAsJson(path, body, token);
}

export function updateCharacter(character, token) {
    const path = `/api/v2/character/swse/character/${character.uuid}`;
    const body = { ...character };
    return Helpers.putAsJson(path, body, token);
}

export function saveCharacter(character, token) {
    if (character.uuid?.length) {
        return updateCharacter(character, token);
    } else {
        return createCharacter(character, token);
    }
}

export function deleteCharacter(id, token) {
    const path = `/api/v2/character/swse/character/${id}`;
    return Helpers.basicFetch("DELETE", path, null, token);
}

export function loadCharacters(token) {
    const path = `/api/v2/character/swse/character`;
    return Helpers.getAsJson(path, token);
}

export function loadSpecies(token) {
    const path = `/api/v2/character/swse/species`;
    return Helpers.getAsJson(path, token);
}

export function loadForcePowers(token) {
    const path = `/api/v2/character/swse/force`;
    return Helpers.getAsJson(path, token);
}

export function loadFeats(token) {
    const path = `/api/v2/character/swse/feats`;
    return Helpers.getAsJson(path, token);
}

export function loadTalents(token) {
    const path = `/api/v2/character/swse/talents`;
    return Helpers.getAsJson(path, token).then(data => {
        const treeGraphs = data.trees.map(tree => Utils.toGraph(tree, data.talents, data.other))
        return { ...data, treeGraphs };
    });
}

export function loadClasses(token) {
    const path = `/api/v2/character/swse/class`;
    return Helpers.getAsJson(path, token);
}

export function loadEquipment(token) {
    const path = `/api/v2/character/swse/equipment`;
    return Helpers.getAsJson(path, token);
}

export function changeSpecies(id, species, token) {
    const path = `/api/v2/character/swse/character/${id}/species`;
    const body = { id: species };
    return Helpers.putAsJson(path, body, token);
}

export function addClass(id, heroicClass, token) {
    const path = `/api/v2/character/swse/character/${id}/class`;
    const body = { id: heroicClass.id};
    return Helpers.putAsJson(path, body, token);
}

export function removeClass(id, heroicClass, token) {
    const path = `/api/v2/character/swse/character/${id}/class/${heroicClass.id}`;
    return Helpers.deleteAsJson(path, token);
}

export function makeChoice(id, {feature, featureType, value}, token) {
    const path = `/api/v2/character/swse/character/${id}/choice/${feature.featureInstance.id}`;
    const body = {
        choiceType: featureType,
        id: value
    };
    return Helpers.postAsJson(path, body, token);
}

export function saveNotes(uuid, notes, token) {
    const path = `/api/v2/character/swse/character/${uuid}/notes`;
    const body = { notes };
    return Helpers.putAsJson(path, body, token);
}

export function addEquipment(uuid, equipmentId, token) {
    const path = `/api/v2/character/swse/character/${uuid}/equipment`;
    const body = { equipmentId };
    return Helpers.postAsJson(path, body, token);
}

export function toggleEquipment(uuid, equipmentId, checked, token) {
    const path = `/api/v2/character/swse/character/${uuid}/equipment/${equipmentId}`;
    const body = { equipped: checked };
    return Helpers.putAsJson(path, body, token);
}

export function removeEquipment(uuid, equipmentId, token) {
    const path = `/api/v2/character/swse/character/${uuid}/equipment/${equipmentId}`;
    return Helpers.deleteAsJson(path, token);
}

export function loadSkills(token) {
    const path = `/api/v2/character/swse/skills`;
    return Helpers.getAsJson(path, token);
}
