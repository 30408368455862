import React, {useState} from "react";
import { Accordion } from "reactstrap";

import "./FeatureAccordion.scss";
import FeatureAccordionItem from "./FeatureAccordionItem";

const FeatureAccordion = ({character, features, defaultOpen, changeFeature, showChoices, classes, feats, talents, skills, forcePowers}) => {
    const [open, setOpen] = useState(defaultOpen || []);
    const toggle = (id) => {
        setOpen((p) => {
                if (p.includes(id)) {
                    return p.filter(i => i !== id);
                } else {
                    return [id, ...p];
                }
            }
        );
    };

    return (
        <div className="feature-accordion">
            <Accordion open={open} toggle={toggle}>
                {features.map(feature => (
                    <FeatureAccordionItem
                        key={feature.uuid}
                        character={character}
                        feature={feature}
                        showChoices={showChoices}
                        changeFeature={changeFeature}
                        classes={classes}
                        skills={skills}
                        feats={feats}
                        talents={talents}
                        forcePowers={forcePowers}
                    />
                ))}
            </Accordion>
        </div>
    );
}

export default FeatureAccordion;