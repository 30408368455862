import React, {useState} from "react";

import {
    Button,
    Carousel,
    CarouselControl,
    CarouselItem,
} from "reactstrap";

import { ClassCard } from "./";

import "./ClassesList.scss";

const ClassesList = ({ character, addClass, removeClass, classes }) => {
    const chClass = character.classes?.length && classes.find(s => s.id === character.classes[0])

    const [activeIndex, setActiveIndex] =
        useState(chClass ? classes.findIndex(s => s.id === chClass.id) : 0);
    const [animating, setAnimating] = useState(false);
    const [carouselOpen, setCarouselOpen] = useState(character.classes.length === 0);

    let chClasses = character.classes.map(c => classes.find(cl => cl.id === c));

    const onAddClass = (data) => {
        addClass(data)
            .then(() => setCarouselOpen(false));
    }

    const cancel = character.classes.length === 0 ?
        null : <span className="cta" onClick={() => setCarouselOpen(false)}>Cancel</span>;


    const sortedFilteredClasses = classes
        .filter(c => !c?.isPrestige || character.availablePrestigeClasses?.includes(c.id))
        .sort((a, b) => a.name.localeCompare(b.name));

    const slides = sortedFilteredClasses.map((item) => {
        const classLevel = character.classes.filter(c => c === item.id).length + 1;
        const heroicLevel = character.classes.length + 1;
        const isMulticlass = classLevel === 1 && heroicLevel > 1;
        return (
            <CarouselItem
                className="class-carousel-item"
                tag="div"
                key={item.id}
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
            >
                <ClassCard
                    canCollapse={false}
                    character={character}
                    heroicClass={item}
                    classLevel={classLevel}
                    heroicLevel={heroicLevel}
                    isMulticlass={isMulticlass}
                    canAdd={true}
                    addClass={onAddClass}
                    cancel={cancel}
                />
            </CarouselItem>
        );
    });

    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === sortedFilteredClasses.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? sortedFilteredClasses.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const counts = {};
    chClasses = chClasses.map(chClass => {
        if (counts[chClass.id] === undefined) {
            counts[chClass.id] = 0;
        }
        const count = counts[chClass.id] + 1;
        return {...chClass, classLevel: count }
    });

    const carousel = (
        <Carousel
            interval={false}
            activeIndex={activeIndex}
            next={next}
            previous={previous}
        >
            {slides}
            <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
                className="carousel-control"
            />
            <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
                className="carousel-control"
            />
        </Carousel>
    );

    const addClassButton = (
        <Button className="level-up-button" onClick={() => setCarouselOpen(true)}>
            <span>Level Up</span>
        </Button>
    )

    return (
        <div className="builder-class">
            <div className="class-list-container">
                <div className="builder-class-list">
                    {chClasses.map((chClass, idx) => {
                        const isMulticlass = idx > 0 && chClasses[0].id !== chClass.id;
                        return (
                            <ClassCard
                                key={`ch-class-${idx}`}
                                canCollapse={true}
                                isOpen={false}
                                character={character}
                                classLevel={idx + 1}
                                heroicClass={chClass}
                                isMulticlass={isMulticlass}
                                canRemove={idx === chClasses.length - 1}
                                removeClass={removeClass}
                            />
                        );
                    })}
                    {carouselOpen ? carousel : addClassButton}
                </div>
            </div>
        </div>
    );
}

export default ClassesList;