import React, {useState} from 'react';

import './Actions.scss';
import {Nav, NavItem, NavLink, TabContent, Table, TabPane} from "reactstrap";

const Actions = ({ character }) => {
    const [currentActiveTab, setCurrentActiveTab] = useState('standard');
    const [collapsed, setCollapsed] = useState({
        "standard-std": true,
        "standard-feature": false,
        "full-round-std": true,
        "full-round-feature": false,
        "swift-std": true,
        "swift-feature": false,
        "reaction-std": true,
        "reaction-feature": false
    });

    // Toggle active state for Tab
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }
    const className = tab => {
        if (currentActiveTab === tab) {
            return "active";
        }
        return "";
    }

    const fullRoundActions = [
        {
            name: "Coup de Grace",
            id: "CoupDeGrace",
            description: "You automatically score a Critical Hit against a Helpless target, dealing double damage."
        },
        {
            name: "Full Attack",
            id: "FullAttack",
            description: "You can make more than one attack. You must be wielding two weapons, wielding a double weapon, or using a special ability that grants extra attacks."
        },
        {
            name: "Run",
            id: "Run",
            description: "When you Run, you can move up to four times your Speed in a straight line (or three times your Speed in a straight line if you are wearing Heavy Armor or carrying a Heavy Load)."
        }
    ];
    const standardActions = [
        {
            name: "Attack (Melee)",
            id: "AttackMelee",
            description: "You can strike any target in a square you can threaten. Small and Medium characters threaten the squares adjacent to them."
        },
        {
            name: "Attack (Ranged)",
            id: "AttackRanged",
            description: "You can throw or shoot at any target within your Line of Sight if there are no obstructions (including other characters) between you and the target."
        },
        {
            name: "Aid Another",
            id: "AidAnother",
            description: "You can aid an ally's next skill check or attack roll, or you can interfere with an enemy's attacks. Make the same kind of Skill check or Ability check, if you roll a 10 or higher you grant a +2 bonus to the other character's check"
        },
        {
            name: "Attack an Object",
            id: "AttackAnObject",
            description: "Sometimes you need to Attack an Object such as a door, a control console, or a held weapon, either to destroy it or bypass it. An unattended, immobile Object has a Reflex Defense of 5 + its size modifier. If you hit it with a Standard Action, you deal damage as normal."
        },
        {
            name: "Charge",
            id: "Charge",
            description: "Uou can move your Speed (minimum 2 squares) in a straight line, and then make a melee attack at the end of your movement. You gain a +2 competence bonus on your attack roll, but take a -2 penalty to your Reflex Defense until the start of your next turn."
        },
        {
            name: "Disarm",
            id: "Disarm",
            description: "You may attempt to Disarm an opponent, forcing them to drop one weapon (or object) that they are holding."
        },
        {
            name: "Fight Defensively",
            id: "FightDefensively",
            description: "You can concentrate more on protecting yourself than hurting your enemies. You can take a -5 penalty on your attack rolls and gain a +2 dodge bonus to your Reflex Defense until the start of your next turn."
        },
        {
            name: "Grab",
            id: "Grab",
            description: "A Grab Attack is treated as an Unarmed Attack (thus provoking Attacks of Opportunity without the Martial Arts I feat), except that it doesn't deal damage and you take a -5 penalty on the attack roll. You can only Grab an opponent up to one size category larger than yourself, and only one opponent at a time."
        },
        {
            name: "Grapple",
            id: "Grapple",
            description: "A Grapple Attack is an improved version of the Grab. You can only make a Grapple Attack (a Standard Action) if you have a Grappling Feat (such as Pin or Trip). You can only Grapple an opponent up to one size category larger than you, and only one opponent at a time."
        }
    ];
    const recover = {
        name: "Recover",
        id: "Recover",
        description: "Spend 3 Swift Actions to move +1 Step on the Condition Track"
    };
    const swiftActions = [
        {
            name: "Activate an Item",
            id: "ActivateAnItem",
            description: " Starting a Vehicle, turning on a computer, and lighting a fusion lantern are all examples of Activating an Item."
        },
        {
            name: "Aim",
            id: "Aim",
            description: "Take two consecutive Swift Actions in the same round to more carefully line up a ranged attack. When you do so, you ignore all Cover bonuses to your target's Reflex Defense on your next attack."
        },
        {
            name: "Catch a Second Wind",
            id: "CatchSecondWind",
            description: "If you are reduced to one-half your maximum Hit Points or less, you can Catch a Second Wind as a Swift Action. You can only Catch a Second Wind once per day. This Action heals one-quarter of your full Hit Point total (rounded down) or a number of points equal to your Constitution score, whichever is greater."
        },
        {
            name: "Drop an Item",
            id: "DropAnItem",
            description: "Dropping an Item is swift (but picking one up is a Move Action). You can Drop an Item so that it falls on the ground in your Fighting Space or lands in an adjacent square."
        },
        {
            name: "Fall Prone",
            id: "FallProne",
            description: "Falling into a Prone position requires a Swift Action."
        },
        recover,
        {
            name: "Switch Weapon Mode",
            id: "SwitchWeaponMode",
            description: "Some weapons have multiple weapon modes. Examples include the Blaster Pistol, which has both a lethal setting and a Stun setting, and a Blaster Carbine, which has both a single-shot mode and an Autofire mode."
        }
    ];
    if (character.features.find(f => f.feature.id.toLowerCase() === "feat.shakeitoff")) {
        recover.description = "Spend 2 Swift Actions to move +1 Step on the Condition Track";
    }
    const reactions = [];

    const actions = {
        "standard": standardActions,
        "full-round": fullRoundActions,
        "swift": swiftActions,
        "reaction": reactions
    };

    const actionsFromCharacter = [
        ...character.features.filter(f => !!f.feature?.args?.time)
            .map(f => ({ ...f.feature, time: f.feature.args.time })),
        ...character.forcePowers.filter(f => !!f.forcePower?.args?.time)
            .map(f => ({ uses: f.uses, ...f.forcePower, time: f.forcePower.args.time }))
    ];

    const actionsFromCharacterByTime =
        Object.groupBy(actionsFromCharacter,item => item.time.toLowerCase());

    const buildTable = (timing) => {
        const toggleCollapsed = (state, key) => {
            const collapsed = state[key];
            return { ...state, [key]: !collapsed };
        }
        const featureKey = `${timing}-feature`;
        const stdKey = `${timing}-std`;
        const featureActions = actionsFromCharacterByTime[timing];
        const featureHeader = collapsed[featureKey] ? "Features >" : "Features v";
        const stdHeader = collapsed[stdKey] ? "Default Actions >" : "Default Actions v";
        const features = featureActions?.length ?
            (
                <>
                    <tr>
                        <td
                            colSpan={2}
                            className="table-category"
                            onClick={() => setCollapsed(s => toggleCollapsed(s, featureKey))}
                        >
                            {featureHeader}
                        </td>
                    </tr>
                    {collapsed[featureKey] ? null :
                        featureActions.map(a =>
                        <tr key={a.name}>
                            <td>{a.name}</td>
                            <td>{a.description}</td>
                        </tr>
                    )}
                </>
            ) : null;

        return (
            <Table className="actions-table">
                <tbody>
                <tr>
                    <td
                        colSpan={2}
                        className="table-category"
                        onClick={() => setCollapsed(s => toggleCollapsed(s, stdKey))}
                    >
                        {stdHeader}
                    </td>
                </tr>
                {collapsed[stdKey] ? null :
                    actions[timing].map(a =>
                    <tr key={a.name}>
                        <td>{a.name}</td>
                        <td>{a.description}</td>
                    </tr>
                )}
                {features}
                </tbody>
            </Table>
        );
    }

    return (
        <div className="actions-container">
            <Nav pills>
                {Object.keys(actions).map(i =>
                    <NavItem key={i}>
                        <NavLink
                            className={className(i)}
                            onClick={() => toggle(i)}
                        >
                            {i.toUpperCase()}
                        </NavLink>
                    </NavItem>
                )}
            </Nav>
            <TabContent activeTab={currentActiveTab} className="actions-tab-panel-content">
                {Object.keys(actions).map(k =>
                    <TabPane tabId={k} key={"tab-" + k}>
                        {buildTable(k)}
                    </TabPane>
                )}
            </TabContent>
        </div>
    );
}

export default Actions;