import React from 'react';

import './Features.scss';
import {Table} from "reactstrap";

const Features = ({ character }) => {
    const excludeFeats = ["SkillTraining", "ForceTraining", "SkillFocus", "WeaponProficiency"];
    const isDisplayable = (f) => {
        const isFeatOrTalent =
            f.feature.id.startsWith("feat.") ||
            f.feature.id.startsWith("talent.");
        const isExclude = excludeFeats.some(ex => f.feature.id.endsWith(ex));
        return isFeatOrTalent && !isExclude;
    }

    const displayableFeatures = character.features.filter(f => isDisplayable(f))
        .map(f => ({
            id: f.id,
            fid: f.feature.id,
            name: f.feature.name,
            description: f.feature.description,
            source: f.sourceId
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

    const displayable = [...displayableFeatures, ...character.forcePowers.map(f => f.forcePower)];

    return (
        <div className="features-container">
            <Table className="features-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                {displayable.map(f =>
                    <tr key={f.id}>
                        <td>{f.name}</td>
                        <td>{f.description}</td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    );
}

export default Features;