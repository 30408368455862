import React, {useRef, useState} from 'react';

import './HitPoints.scss';
import {Input} from "reactstrap";

const HitPoints = ({ hitPoints, updateHitPoints }) => {
    const [editable, setEditable] = useState(false);
    const [currentHp, setCurrentHp] = useState(hitPoints.current || hitPoints.max || 0);
    const inputRef = useRef(null);

    const handleBlur = () => {
        updateHitPoints(currentHp || 0);
        setEditable(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleBlur();
        }
    };

    const input = (
        <Input
            innerRef={inputRef}
            value={currentHp}
            type="number"
            className="hp-current-input"
            onChange={e => setCurrentHp(parseInt(e.target.value, 10))}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
        />
    );

    const span = (
        <span
            className="hp-current-span"
            onClick={() => {
                setEditable(true);
                setTimeout(() => {
                    inputRef.current?.focus();
                    inputRef.current?.select();
                    }, 0);
            }}
        >
            {hitPoints.current || 0}
        </span>
    );

    return (
        <div className="hp-box-container">
            <div className="hp-box">
                <div className="hp-total-div">
                    {editable ? input : span}
                    <span className="hp-divider">/</span>
                    <span className="hp-total">{hitPoints.max || 0}</span>
                </div>
                <span className="hp-label">Hit Points</span>
            </div>
        </div>
    );
}

export default HitPoints;