import React, {useState} from 'react';

import './Inventory.scss';
import {Button, Input, Offcanvas, OffcanvasBody, OffcanvasHeader, Spinner, Table} from "reactstrap";
import {formatBonus} from "../../utils";

import { ReactComponent as Trash } from "../../assets/trash.svg";

const Inventory = ({loading, equipmentList, inventory, equipmentType, addEquipment, toggleEquipment, removeEquipment}) => {
    const [show, setShow] = useState(false);

    const getKey = (e) => {
        switch (e.toLowerCase()) {
            case "weapon":
                return "weapons";
            default:
                return e.toLowerCase();
        }
    };

    const key = getKey(equipmentType);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const buildHeader = () => {
        switch (key) {
            case "weapons":
                return (
                    <tr>
                        <th></th>
                        <th>TYPE</th>
                        <th>NAME</th>
                        <th>ATTACK</th>
                        <th>DAMAGE</th>
                        <th></th>
                    </tr>
                );
            case "armor":
                return (
                    <tr>
                        <th></th>
                        <th>TYPE</th>
                        <th>NAME</th>
                        <th>REFLEX</th>
                        <th>FORT</th>
                        <th>MAX</th>
                        <th></th>
                    </tr>
                );
            case "gear":
                return (
                    <tr>
                        <th></th>
                        <th>NAME</th>
                        <th>QTY</th>
                        <th></th>
                    </tr>
                );
            default:
                return (
                    <thead>
                        <tr></tr>
                    </thead>
                );
        }
    };

    const buildRow = (e) => {
        const equippedCheckbox = (
            <td>
                <Input type="checkbox" checked={e.equipped}
                       onChange={(evt) => toggleEquipment(e.id, evt.target.checked)}/>
            </td>
        );
        const removeButton = (
            <td>
                <Button className="delete-button" onClick={() => removeEquipment(e.id)}>
                    <Trash className="icon"/>
                </Button>
            </td>
        );
        switch (key) {
            case "weapons":
                return (
                    <tr key={e.id}>
                        {equippedCheckbox}
                        <td>{e.weaponGroupId}</td>
                        <td>{e.name}</td>
                        <td>{e.attack ? formatBonus(e.attack) : ""}</td>
                        <td>{e.damage}</td>
                        {removeButton}
                    </tr>
                );
            case "armor":
                return (
                    <tr key={e.id}>
                        {equippedCheckbox}
                        <td>{e.armorGroupId}</td>
                        <td>{e.name}</td>
                        <td>{e.armorBonus ? formatBonus(e.armorBonus) : ""}</td>
                        <td>{e.equipmentBonus ? formatBonus(e.equipmentBonus) : ""}</td>
                        <td>{e.maxDexBonus ? formatBonus(e.maxDexBonus) : ""}</td>
                        {removeButton}
                    </tr>
                );
            case "gear":
                return (
                    <tr key={e.id}>
                        <td></td>
                        <td>{e.name}</td>
                        <td>1</td>
                        {removeButton}
                    </tr>
                );
            default:
                return <tr key={e.id} />;
        }
    }

    const buildTable = (characterInventory, equipmentType) => (
        <Table className="equipment-table">
            <thead>
                {buildHeader()}
            </thead>
            <tbody>
                {characterInventory
                    .filter(e => e.equipment.type.toLowerCase() === equipmentType.toLowerCase())
                    .map(e => ({...e.equipment, ...e}))
                    .map(e => buildRow(e))}
                <tr className="no-border">
                    <td className="add-button-cell">
                        <Button className="add-button" onClick={handleShow}>
                            Add
                        </Button>
                    </td>
                    <td/>
                    <td/>
                    <td/>
                    <td/>
                </tr>
            </tbody>
        </Table>
    );

    const getTypeString = (equipment) => {
        switch (equipment?.type?.toLowerCase()) {
            case "weapon":
                return equipment.weaponGroupId?.toUpperCase() || "WEAPON";
            case "armor":
                return equipment.armorGroupId?.toUpperCase() || "ARMOR";
            default:
                return "GEAR";
        }
    }

    let content = <Spinner />;
    if (!loading) {
        const showType = ["weapon", "armor"].includes(equipmentType?.toLowerCase());
        content = (
            <div className="equipment-list box">
                <Table className="equipment-table add-dialog">
                    <thead>
                    <tr>
                        {showType ? <th>TYPE</th> : null}
                        <th>NAME</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {equipmentList?.[key]
                        ?.sort((a, b) => a.name.localeCompare(b.name))
                        ?.map(a =>
                        <tr key={a.id}>
                            {showType ? <td>{getTypeString(a)}</td> : null}
                            <td>{a.name}</td>
                            <td>
                                <Button className="add-button" onClick={() => addEquipment(a.id)
                                    .then(handleClose)}>
                                    Add
                                </Button>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div>
        )
    }

    return (
        <div className="inventory-container">
            <div className="inventory">
                {buildTable(inventory, equipmentType)}
                <Offcanvas isOpen={show} className="inventory-canvas">
                    <OffcanvasHeader className="canvas-header" toggle={handleClose}>
                        Add {equipmentType}
                    </OffcanvasHeader>
                    <OffcanvasBody className="canvas-body">
                        {content}
                    </OffcanvasBody>
                </Offcanvas>
            </div>
        </div>
    );
}

export default Inventory;