import React from "react";

import {
    AbilityScores,
    Defenses,
    HitPoints,
    LargeBoxWithScore,
    Proficiencies,
    StackBoxes,
    Skills,
    TabbedFeatures,
    TabbedInventory,
} from './';

import './Character.scss';

const Character = ({ character, setCharacter, addEquipment, toggleEquipment, removeEquipment, saveCharacter, saveNotes, species, classes }) => {

    const apply = (func) => {
        func(character);
        saveCharacter(character);
    }

    const guardZero = (i) => {
        if (i < 0) return 0; else return i;
    }

    const points = [
        {
            label: "Destiny",
            total: character.destinyPoints,
            increment: () => apply(ch => ch.destinyPoints += 1),
            decrement: () => apply(ch => guardZero(ch.destinyPoints -= 1)),
            reset: () => {}
        },
        {
            label: "Force",
            total: character.forcePoints?.current,
            increment: () => apply(ch => ch.forcePoints.current += 1),
            decrement: () => apply(ch => guardZero(ch.forcePoints.current -= 1)),
            reset: () => apply(ch => ch.forcePoints.current = ch.forcePoints.max)

        },
        {
            label: "Dark Side",
            total: character.darkSideScore || 0,
            increment: () => apply(ch => ch.darkSideScore += 1),
            decrement: () => apply(ch => guardZero(ch.darkSideScore -= 1)),
            reset: () => {}
        }
    ];

    const updateHitPoints = (value) => apply(ch => {
        ch.hitPoints.current = Math.min(guardZero(value), ch.hitPoints.max);
    });

    return (
        <div className="character-container">
            <div className="character-stats">
                <div className="top-bar">
                    <AbilityScores abilities={character.abilities}/>
                    <StackBoxes boxes={points} />
                    <LargeBoxWithScore skill={character.skills["skill.Initiative"]} />
                    <LargeBoxWithScore skill={character.skills["skill.Perception"]} />
                    <HitPoints hitPoints={character.hitPoints} updateHitPoints={updateHitPoints}/>
                </div>
                <div className="two-column">
                    <div className="column">
                        <Defenses defenses={character.defenses} />
                        <Proficiencies proficiencies={character.proficiencies} />
                        <TabbedInventory
                            character={character}
                            addEquipment={addEquipment}
                            toggleEquipment={toggleEquipment}
                            removeEquipment={removeEquipment}
                        />
                    </div>
                    <div className="column no-flex">
                        <Skills skills={character.skills}/>
                    </div>
                    <div className="column">
                        <TabbedFeatures
                            character={character}
                            setCharacter={setCharacter}
                            saveNotes={saveNotes}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Character;