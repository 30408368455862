import React, {useState} from 'react';

import './Notes.scss';
import {Input} from "reactstrap";
import {Autosave} from "../../common";

const Notes = ({character, setCharacter, saveNotes}) => {
    const [notes, setNotes] = useState(character.notes || "");
    return (
        <div className="notes-container">
            <Autosave
                data={notes}
                saveData={(n) => {
                    if (n !== notes) {
                        return saveNotes(n)
                            .then(() => setCharacter(c => ({...c, notes: n})));
                    }
                }}
            />

            <Input
                className="notes-input"
                type="textarea"
                placeholder="Write notes here..."
                value={notes}
                onChange={(evt) => setNotes(evt.target.value)}
            />
        </div>
    );
}

export default Notes;