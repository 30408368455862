import React, {useState} from "react";


import { ReactComponent as TrashSvg } from "../../assets/trash.svg";

import "./FeatureAccordion.scss";
import {
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Badge,
    Input,
} from "reactstrap";

import {default as TableSelectionButton} from "./TableSelectionButton";
import ConfirmDependenciesModal from "./ConfirmDependenciesModal";
import WarningModal from "./WarningModal";

const findFeatureInstance = (character, id) => {
    return character.features.find(f => f.id === id)
}
const findSkillId = (character, featureValue) => {
    return findFeatureInstance(character, featureValue)?.feature?.args?.skill;
}

const getValue = (character, feature) => {
    switch (feature?.choiceType) {
        case "skill":
            return findSkillId(character, feature.value);
        case "feat":
        case "talent":
            if (feature.value?.includes("-")) {
                const found = character.features.find(f => f.id === feature.value)?.feature?.id;
                return found;
            }
            return feature;
        case "forcePower":
        case "weaponGroupId":
            const argKey = feature.choiceType === "weaponGroupId" ? feature.choiceType : "forcePowerId";
            if (feature.value?.includes("-")) {
                const profFeature = character.features.find(f => f.id === feature.value)
                if (profFeature?.feature?.args?.[argKey]) {
                    return profFeature.feature.args[argKey];
                }
            }
            return feature;
        case "abilityScoreIncrease":
            const instance = findFeatureInstance(character, feature.value);
            return instance?.feature?.args?.ability;
        default:
            return null;
    }
}

const getChoiceDetails = ({character, feature, classes, skills, feats, talents, forcePowers}) => {
    let value = getValue(character, feature);
    let options;
    let sort = true;
    switch(feature?.choiceType) {
        case "skill":
            const skillId = value;
            options = skills
                .map(s => ({ ...s, name: `${s.name} (${s.abilityId})`}));
            if (feature.id === "feat.SkillTraining") {
                const filteredOptions = options
                    .filter(s => {
                        const isInClassSkills = !!s.classes.filter(c => character.classes.includes(c))?.length;
                        const isTrained = character.skills[s.id].trained;
                        const matchesSelection = !!(skillId?.length && s.id === skillId);

                        return (isInClassSkills && !isTrained) || matchesSelection;
                    });
                options = filteredOptions;
            } else {
                options = options
                    .filter(s => s.trained && (!s.focused || (skillId?.length && s.id === skillId)));
            }
            value = skillId;
            break;
        case "forcePower":
            const powerInstance = findFeatureInstance(character, feature.value);
            if (feature.value?.includes("-")) {
                value = powerInstance?.feature?.args?.forcePowerId;
            }

            options = [...forcePowers];
            break;
        case "feat":
            const featInstance = findFeatureInstance(character, feature.value);
            if (feature.value?.includes("-")) {
                value = featInstance?.feature?.id;
            }
            let checkClassFeat = (_) => true;
            if (feature.id === "feature.ClassBonusFeat") {
                const classId = feature.args.class
                checkClassFeat = (f) => f.args.classes?.includes(classId);
            }

            let baseOptions = character.availableFeats;
            if (feature.args?.multiclass) {
                baseOptions = feature.args.options
                    .filter(o => character.availableFeats.find(f => f.id.toLowerCase() === o.toLowerCase()))
                    .map(o => feats.find(f => f.id === o))
                    .filter(o => o !== undefined);
            }

            const existingFeatIds = [...new Set(character.features
                .map(f => f.feature.id)
                .filter(id => id.startsWith("feat.")))];

            options = baseOptions
                .filter(s => {
                    if (!s.args?.multipleAllowed && existingFeatIds.includes(s.id) && value !== s.id) {
                        return false;
                    } else {
                        return value === s.id || (
                            checkClassFeat(s));
                    }
                })
                // remove any features where the selected feature is a preReq
                // TODO: use dependency tree
                .filter(s => {
                    return s.prereqs?.hasFeature?.id !== value
                });
            break;
        case "talent":
            const talentInstance = findFeatureInstance(character, feature.value);
            if (feature.value?.includes("-")) {
                value = talentInstance?.feature?.id;
            }

            const existingTalentIds = [...new Set(character.features
                .map(f => f.feature.id)
                .filter(id => id.startsWith("talent.")))];

            const classId = feature.args.class
            const baseTalents = character.availableTalentsByClass[classId]
            options = baseTalents
                .filter(s => {
                    if (!s.args?.multipleAllowed && existingTalentIds.includes(s.id) && value !== s.id) {
                        return false;
                    } else {
                        return true;
                    }
                })
                .filter(s => {
                    // TODO: could dependency tree be used here too?
                    return s.prereqs?.hasFeature?.id !== value
                });
            break;
        case "weaponGroupId":
            let baseWeaponOptions = ["Advanced", "Heavy", "Pistols", "Rifles", "Simple"];
            const existingFocus = character.features
                .filter(f => f.feature.id === "feature.WeaponFocus")
                .map(f => f.feature.arg?.weaponGroupId);
            const existingProfs = character.proficiencies.weapon;

            if (feature.id === "feat.WeaponFocus") {
                options = existingProfs
                    .filter(f => f === value || !existingFocus.includes(f))
                    .map(w => ({ id: w, name: w }));
            } else {
                if (feature.id === "feat.WeaponProficiency") {
                    if (feature.parentFeature.id === "feature.ClassBonusFeat") {
                        const heroicClass = feature.parentFeature.args.class;
                        baseWeaponOptions = feature.args[heroicClass];
                    } else if (feature.parentFeature.args?.multiclass) {
                        const multiclassOptions = feature.args?.multiclassOptions;
                        baseWeaponOptions = multiclassOptions[feature.parentFeature.args.class] || [];
                    }
                    options = baseWeaponOptions
                        .filter(f => f === value || !existingProfs.includes(f))
                        .map(w => ({ id: w, name: w }));
                } else {
                    options = baseWeaponOptions
                        .filter(f => f === value || existingProfs.includes(f))
                        .map(w => ({ id: w, name: w }));
                }
            }
            break;
        case "abilityScoreIncrease":
            options = [
                { id: "STR", name: "Strength" },
                { id: "DEX", name: "Dexterity" },
                { id: "CON", name: "Constitution" },
                { id: "INT", name: "Intelligence" },
                { id: "WIS", name: "Wisdom" },
                { id: "CHA", name: "Charisma" },
            ];
            sort = false;

            // need to remove any score found in the other ASI choice for this same group
            const invalidScores = character.features
                .filter(f => f.sourceId === feature?.sourceId && f.id !== feature?.id)
                .map(f => character.features.find(ff => ff.sourceId === f.id)?.feature?.args?.ability)
                .filter(score => !!score);
            options = options.filter(f => !invalidScores.includes(f.id));

            break;
        default:
            options = [];
            break;
    }
    if (sort) {
        options = options.sort((a, b) => a.name.localeCompare(b.name))
    }

    return { value, options };
}

const ChoiceDescription = ({ choiceDetails }) => {
    if (!choiceDetails?.value) {
        return null;
    }

    const option = choiceDetails.options?.find(opt => opt.id === choiceDetails.value);

    if (option?.description) {
        return <span className="choice-description">{option.description}</span>
    }
    return null;
}

const ChoiceDropdown = ({ character, feature, choiceDetails, changeFeature, showChoices, hideTable, talents }) => {
    const [modals, setModals] = useState({});

    const closeModal = (name) => setModals(p => ({ ...p, [name]: null}));
    const openModal = (name, component) => setModals(p => ({ ...p, [name]: component}));

    const confirmDependencyModal = (confirmChanges) => (
        <ConfirmDependenciesModal
            confirmChanges={confirmChanges}
            dependencies={dependencies}
            confirm={() => {
                changeFeature(confirmChanges);
                closeModal("confirmDependency");
            }}
            close={() => closeModal("confirmDependency")}
        />
    );

    const namify = (str) => {
        switch (str) {
            case "abilityScoreIncrease":
                return "ability score increase for";
            default:
                return str;
        }
    }

    const findName = (id) => choiceDetails.options?.find(opt => opt.id === id)?.name;
    const confirmRemoveModal = () => (
        <WarningModal
            isOpen
            title="Are you sure?"
            confirm={() => {
                changeFeature({ feature, featureType, value: "" });
                closeModal("confirmRemove");
            }}
            close={() => closeModal("confirmRemove")}
        >
            This will remove the {namify(featureType)} {findName(value)}{featureType === "abilityScoreIncrease" ? "" : " and any other features that have it as a prerequisite"}.
        </WarningModal>
    );

    const openConfirmationModal = (confirmChanges) =>
        openModal("confirmDependency", confirmDependencyModal(confirmChanges));
    const openRemoveModal = () =>
        openModal("confirmRemove", confirmRemoveModal());

    const featureType = feature?.choiceType;
    const hasChoice = ["feat", "talent", "skill", "forcePower", "weaponGroupId", "abilityScoreIncrease"].includes(featureType);

    if (!feature || !showChoices || !hasChoice) {
        return null;
    }

    const { value, options } = choiceDetails;

    const items = options
        .map(opt =>
            <option key={feature.id + "-" + opt.id} value={opt.id}>
                {opt.name}
            </option>
        );
    items.unshift(<option key={feature.id + "-nochoice"}/>);

    let dependencies = [];
    if (character.featureDependencies) {
        dependencies = Object.entries(character.featureDependencies)
            .filter(e => e[1].find(f => f.id === feature.uuid))
            .flatMap(e => {
                const found = character.features.find(f => f.id === e[0]);
                return {
                    id: found?.feature?.id?.split(".")?.slice(-1),
                    name: found?.feature?.name
                }
            });
    }

    const handleRemoveChoice = () => {
        openRemoveModal();
    }
    const handleChoiceChange = (value) => {
        if (dependencies?.length) {
            openConfirmationModal({
                feature,
                featureType,
                value
            });
        } else {
            changeFeature({
                feature,
                featureType,
                value
            })
        }
    };

    let viewAsTable = null;

    if (!hideTable) {
        viewAsTable = (
            <TableSelectionButton
                features={options}
                selectedId={value}
                feature={feature}
                featureType={featureType}
                talents={talents}
                character={character}
                changeFeature={changeFeature}
                dependencies={dependencies}
            />
        );
    }

    let viewAsTree = null;
    if (featureType === "talent") {
        viewAsTree = <TableSelectionButton
            mode="tree"
            features={options}
            selectedId={value}
            feature={feature}
            featureType={featureType}
            talents={talents}
            character={character}
            changeFeature={changeFeature}
            dependencies={dependencies}
        />;
    }

    let trash = null;
    if (value?.length) {
        trash = <TrashSvg className="img-button-icon" onClick={() => handleRemoveChoice()} />;
    }

    return (
        <div className="feature-choice-dropdown-row">
            <Input
                key={feature.id + "-input"}
                name={`Select a ${featureType}`}
                type="select"
                value={value || ''}
                onChange={(evt) => handleChoiceChange(evt.target.value)}
            >
                {items}
            </Input>
            {viewAsTable}
            {viewAsTree}
            {trash}
            {modals["confirmDependency"]}
            {modals["confirmRemove"]}
        </div>
    );
}

const FeatureAccordionItem = ({character, feature, showChoices, hideTable, changeFeature, classes, skills, feats, talents, forcePowers, mode }) => {
    const choiceDetails = getChoiceDetails({
        character, feature, classes, skills, feats, talents, forcePowers
    });

    let linkedFeature, linkedChoiceDetails;
    const selectedFeature = character.choices.find(choice => choice.id === feature?.value);

    if (selectedFeature && selectedFeature?.choiceType) {
        linkedFeature = {
            ...selectedFeature,
            ...selectedFeature.featureInstance.feature,
            parentFeature: feature,
            uuid: selectedFeature.featureInstance.id
        };
        linkedChoiceDetails = getChoiceDetails({
            character, feature: linkedFeature, classes, skills, feats, talents
        });
    }

    const showBadge = (f) => !f?.value && f?.choiceType;

    const featureChoiceClassNames = ["feature-choice"];
    if (linkedFeature) {
        featureChoiceClassNames.push("has-linked-choice");
    }

    const featureBody = (
        <div className="feature">
            <span className="feature-description">{feature.description}</span>
            <div className={featureChoiceClassNames.join(" ")}>
                <ChoiceDropdown
                    character={character}
                    choiceDetails={choiceDetails}
                    feature={feature}
                    changeFeature={changeFeature}
                    showChoices={showChoices}
                    hideTable={hideTable}
                    talents={talents}
                />
                <ChoiceDescription character={character} choiceDetails={choiceDetails}/>
            </div>
            <div className="feature-choice feature-subchoice">
                <ChoiceDropdown
                    character={character}
                    choiceDetails={linkedChoiceDetails}
                    feature={linkedFeature}
                    parentFeature={feature}
                    changeFeature={changeFeature}
                    showChoices={showChoices}
                    hideTable={hideTable}
                    talents={talents}
                />
                <ChoiceDescription character={character} choiceDetails={linkedChoiceDetails}/>
            </div>
        </div>
    );

    if (mode === "raw") {
        return featureBody;
    }

    return (
        <AccordionItem>
            <AccordionHeader targetId={feature.uuid}>
                <span>{feature.name}</span>
                {showBadge(feature) || showBadge(linkedFeature) ? <Badge pill>!</Badge> : null}
            </AccordionHeader>
            <AccordionBody accordionId={feature.uuid}>
                {featureBody}
            </AccordionBody>
        </AccordionItem>
    )
}

export default FeatureAccordionItem;