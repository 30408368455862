import React, {useEffect, useState} from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import { Inventory } from "./";

import './TabbedInventory.scss';
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../firebase";
import * as Api from "../../api/Api";

const TabbedInventory = ({ character, addEquipment, toggleEquipment, removeEquipment }) => {
    const [currentActiveTab, setCurrentActiveTab] = useState("weapons");
    const [user] = useAuthState(auth);
    const [loading, setLoading] = useState(true);
    const [equipmentList, setEquipmentList] = useState([]);

    useEffect(() => {
        if (!user) {
            return;
        }

        Api.loadEquipment(user.accessToken)
            .then(data => {
                setEquipmentList(data);
                setLoading(false);
            });
    }, [user]);

    // Toggle active state for Tab
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }
    const className = tab => {
        if (currentActiveTab === tab) {
            return "active";
        }
        return "";
    }

    return (
        <div className="inventory-tab-panel-container">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={className("weapons")}
                        onClick={() => toggle("weapons")}
                    >
                        Weapons
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={className("armor")}
                        onClick={() => toggle("armor")}
                    >
                        Armor
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={className("gear")}
                        onClick={() => toggle("gear")}
                    >
                        Gear
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={currentActiveTab} className="tab-panel-content box">
                <TabPane tabId="weapons">
                    <Inventory
                        loading={loading}
                        equipmentList={equipmentList}
                        inventory={character.equipment}
                        equipmentType="Weapon"
                        addEquipment={addEquipment}
                        toggleEquipment={toggleEquipment}
                        removeEquipment={removeEquipment}
                    />
                </TabPane>
                <TabPane tabId="armor">
                    <Inventory
                        loading={loading}
                        equipmentList={equipmentList}
                        inventory={character.equipment}
                        equipmentType="Armor"
                        addEquipment={addEquipment}
                        toggleEquipment={toggleEquipment}
                        removeEquipment={removeEquipment}
                    />
                </TabPane>
                <TabPane tabId="gear">
                    <Inventory
                        loading={loading}
                        equipmentList={equipmentList}
                        inventory={character.equipment}
                        equipmentType="Gear"
                        addEquipment={addEquipment}
                        toggleEquipment={toggleEquipment}
                        removeEquipment={removeEquipment}
                    />
                </TabPane>
            </TabContent>
        </div>
    );
}

export default TabbedInventory;